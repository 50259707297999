<template>
  <div>
    <counting-cards
      v-if="itemLocal.counting"
      :data="itemLocal.counting"
    />
    <b-row>
      <b-col cols="12">
        <cash-flow-chart :data="itemLocal.cashflow" />
      </b-col>
    </b-row>

    <b-row class="match-height">
      <b-col cols="6">
        <deposit-table :rows="itemLocal.deposit" />
      </b-col>
      <b-col cols="6">
        <prepaid-table />
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  BRow, BCol,
} from 'bootstrap-vue';
import CountingCards from './CountingCards.vue';
import useReportFinance from './useReportFinance';
import CashFlowChart from './CashFlowChart.vue';
import DepositTable from './DepositTable.vue';
import PrepaidTable from './PrepaidTable.vue';

// eslint-disable-next-line import/no-cycle

export default {
  components: {
    BRow,
    BCol,
    CountingCards,
    CashFlowChart,
    DepositTable,
    PrepaidTable,
  },
  setup() {
    const {
      fetchData, itemLocal,
    } = useReportFinance();

    return {
      fetchData,
      itemLocal,
    };
  },
  created() {
    this.fetchData();
  },
};
</script>

<style lang="scss">
  @import "@core/scss/vue/libs/vue-good-table.scss";
  .vgt-wrap {
    .vgt-table {
      thead {
        th {
          font-size: 1rem !important;
          font-weight: 500;
        }
      }
      td {
        font-size: 1rem !important;
        font-weight: 500;
      }
    }
  }
  .apartment-list-container {
    .vgt-table {
        thead {
          tr {
            th {
              min-width: 120px !important;

              &:first-child {
                min-width: 50px !important;
              }

              &:nth-child(4) {
                 min-width: 300px !important;
              }
            }
          }
        }
      }
  }
  </style>
