import store from '@/store';
import toast from '@/utils/toast';
import { ref } from '@vue/composition-api';

export default function useReportFinance() {
  // Use toast
  const toastification = toast();

  const itemLocal = ref({
    counting: {},
    cashflow: {},
    prepaid: [],
    deposit: [],
  });

  //   API Call

  const getReportFinanceCashFlow = () => {
    store.dispatch('report/getReportFinanceCashFlow').then(response => {
      itemLocal.value.cashflow = response.data;
    }).catch(error => {
      toastification.showToastError(error);
    });
  };

  const getReportFinanceCounting = () => {
    store.dispatch('report/getReportFinanceCounting').then(response => {
      itemLocal.value.counting = response.data;
    }).catch(error => {
      toastification.showToastError(error);
    });
  };

  const getReportFinanceDeposit = () => {
    store.dispatch('report/getReportFinanceDeposit').then(response => {
      itemLocal.value.deposit = response.data;
    }).catch(error => {
      toastification.showToastError(error);
    });
  };

  const getReportFinancePrepaid = () => {
    store.dispatch('report/getReportFinancePrepaid').then(response => {
      itemLocal.value.prepaid = response.data;
    }).catch(error => {
      toastification.showToastError(error);
    });
  };

  const fetchData = () => {
    getReportFinanceCashFlow();
    getReportFinanceCounting();
    getReportFinanceDeposit();
    getReportFinancePrepaid();
  };

  return {
    itemLocal,
    fetchData,
  };
}
