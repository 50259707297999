<template>
  <div>

    <!-- Stats Card Vertical -->
    <b-row class="match-height">
      <b-col
        xl="2"
        md="4"
        sm="6"
      >
        <statistic-card-vertical
          icon="CreditCardIcon"
          :statistic="Number(data.totalIncomes || 0).toLocaleString()"
          :statistic-title="t('Đã thu')"
          color="primary"
        />
      </b-col>
      <b-col
        xl="2"
        md="4"
        sm="6"
      >
        <statistic-card-vertical
          icon="CreditCardIcon"
          :statistic="Number(data.totalExpenses || 0).toLocaleString()"
          :statistic-title="t('Tổng chi')"
          color="primary"
        />
      </b-col>
      <b-col
        xl="2"
        md="4"
        sm="6"
      >
        <statistic-card-vertical
          icon="CreditCardIcon"
          :statistic="Number(data.expectIncomes || 0).toLocaleString()"
          :statistic-title="t('Phải thu')"
          color="primary"
        />
      </b-col>
      <b-col
        xl="2"
        md="4"
        sm="6"
      >
        <statistic-card-vertical
          icon="CreditCardIcon"
          :statistic="Number(data.expectExpenses || 0).toLocaleString()"
          :statistic-title="t('Phải chi')"
          color="primary"
        />
      </b-col>
      <b-col
        xl="2"
        md="4"
        sm="6"
      >
        <statistic-card-vertical
          icon="CreditCardIcon"
          :statistic="Number(data.totalPrepaids || 0).toLocaleString()"
          :statistic-title="t('Tiền thừa khách')"
          color="primary"
        />
      </b-col>
      <b-col
        xl="2"
        md="4"
        sm="6"
      >
        <statistic-card-vertical
          icon="CreditCardIcon"
          :statistic="Number(data.totalDeposit || 0).toLocaleString()"
          :statistic-title="t('Tiền cọc')"
          color="primary"
        />
      </b-col>

    </b-row>

  </div>
</template>

<script>
import {
  BRow, BCol,
} from 'bootstrap-vue';

// eslint-disable-next-line import/no-cycle
import StatisticCardVertical from '@core/components/statistics-cards/StatisticCardVertical.vue';
import { useUtils as useI18nUtils } from '@core/libs/i18n';

export default {
  components: {
    BRow,
    BCol,
    StatisticCardVertical,
  },
  props: {
    data: {
      type: Object,
      default: () => {},
    },
  },

  setup() {
    const { t } = useI18nUtils();
    return { t };
  },

};
</script>
